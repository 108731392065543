import React from 'react'
import './App.css';
import Select from "react-select";

let rowLog = [];
let dataLog = [];
let arrayLog = [];

class WorkerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            firstname: '',
            lastname:'',
            wage: 0.0,
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pracownicyLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog = []
            rowLog[0] = this.state.jsoninLog[i].id;
            rowLog[1] = this.state.jsoninLog[i].Imie;
            rowLog[2] = this.state.jsoninLog[i].Nazwisko;
            dataLog.push(rowLog);
            let obj = {};
            obj['value'] = this.state.jsoninLog[i].id;
            obj['label'] = this.state.jsoninLog[i].id;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>id</td><td>Imie</td><td>Nazwisko</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pracownicyLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    onSubmit(event){

        const myObj = {
            id: this.state.id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            wage: this.state.wage
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    render() {
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Dodaj pracownika</h1>
                        <div className="Form-row"> <p className="Form-label">Kod pracownika :</p>  <input className="Form-input" name = "id" value={this.state.id} onChange={this.handleInputChange}/></div>
                        <div className="Form-row"> <p className="Form-label">Imię: </p> <input className="Form-input" name = "firstname" value={this.state.firstname} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Nazwisko :</p> <input className="Form-input" name = "lastname" value={this.state.lastname} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Pracownicy</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                        </article>
                </section>
            </div>
        );
    }
}

export default WorkerPage
