import React from 'react'
import './App.css';

class Wydajnosc extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            worker: '',
            day: '',
            month: '',
            year: '',
            hours: 0,
            stand: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    onSubmit(event){

        const myObj = {
            worker: this.state.worker,
            stand: this.state.stand,
            day: this.state.year + this.state.month + this.state.day,
            hours: this.state.hours,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wydajnosc', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    render() {
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Wydajnosc</h1>
                        <div className="Form-row"> <p className="Form-label">Data :</p> <input className="Form-input" name = "day" value={this.state.day} onChange={this.handleInputChange}/> <input className="Form-input" name = "month" value={this.state.month} onChange={this.handleInputChange}/> <input className="Form-input" name = "year" value={this.state.year} onChange={this.handleInputChange}/></div>
                        <div className="Form-row"> <p className="Form-label">Pracownik: </p> <input className="Form-input" name = "worker" value={this.state.worker} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Stanowisko :</p> <input className="Form-input" name = "stand" value={this.state.stand} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Godziny :</p> <input className="Form-input" name = "hours" value={this.state.hours} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                    </article>
                </section>
            </div>
        );
    }


}

export default Wydajnosc
