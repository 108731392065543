import React from 'react'
import './App.css';
import DatePicker from "react-datepicker";
import Moment from 'moment';



class Zamowienia extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            terminDate: '',
            month2: '',
            year2: '',
            nip: 0,
            price: 0.0,
            warnings: '',
            number: 0,
            done: 0,
            send: 0,
            pay: 0,
            fvat: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    handleChangeTermin = terminDate => {
        this.setState({
            terminDate
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value });
    }

    onSubmit(event){
        const myObj = {
            day: Moment(this.state.startDate).format('yyyyMMDD'),
            termin: Moment(this.state.terminDate).format('yyyyMMDD'),
            nip: this.state.nip,
            price: this.state.price,
            number: this.state.number,
            warnings: this.state.warnings,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zamowienia', requestOptions)
            .then(response => (
                console.log(response)
            ))
            .then(data => {
                console.log(data);
            })
    }

    render() {
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Zamówienia</h1>
                        <div className="Form-row"> <p className="Form-label">Wpłynęło :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Termin :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.terminDate}
                            onChange={this.handleChangeTermin}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">NIP/PESEL klienta: </p> <input className="Form-input" name = "nip" value={this.state.nip} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Cena :</p> <input className="Form-input" name = "price" value={this.state.price} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Uwagi :</p> <input className="Form-input" name = "warnings" value={this.state.warnings} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Ilość: </p> <input className="Form-input" name = "number" value={this.state.number} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                    </article>
                </section>
            </div>
        );
    }

}

export default Zamowienia
