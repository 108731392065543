import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';

let options = [];
let obj = {};
let arrayWorkers = [];
let rowLog = [];
let dataLog = [];
let arrayLog = [];

class ZakupyKompozyty extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            element: '',
            digit: '',
            number: 0,
            price:0.0,
            deliver: 0.0,
            startDate: '',
            supply:0,
            fvat:'',
            jsonin:'',
            selectedOption: '',
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        let obj;
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/composites')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonin: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/kompozytyLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog = []
            rowLog[0] = this.state.jsoninLog[i].Id;
            rowLog[1] = this.state.jsoninLog[i].Element;
            rowLog[2] = this.state.jsoninLog[i].Jednostka;
            rowLog[4] = this.state.jsoninLog[i].Ilosc;
            rowLog[6] = this.state.jsoninLog[i].Cena;
            rowLog[7] = this.state.jsoninLog[i].Przesylka;
            rowLog[8] = this.state.jsoninLog[i].Wartosc;
            rowLog[9] = this.state.jsoninLog[i].Dostawca;
            rowLog[10] = this.state.jsoninLog[i].Data;
            dataLog.push(rowLog);
            obj = {};
            obj['value'] = this.state.jsoninLog[i].Id;
            obj['label'] = this.state.jsoninLog[i].Id;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Id</td><td>Element</td><td>Jednostka</td><td>Ilość</td><td>Cena</td><td>Przesyłka</td><td>Wartość</td><td>Dostawca</td><td>Data</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/kompozytyLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    createSelectBox()
    {
        options = [];
        for (let i in this.state.jsonin)
        {
            console.log(this.state.jsonin[i].Typ);
            let obj = {};
            obj['value'] = this.state.jsonin[i].Typ;
            obj['label'] = this.state.jsonin[i].Typ;
            options.push(obj);
            console.log(obj);
        }

        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    onSubmit(event){

        const myObj = {
            element: this.state.selectedOption.value,
            digit: this.state.digit,
            number: this.state.number,
            price: this.state.price,
            deliver: this.state.deliver,
            day: Moment(this.state.startDate).format('yyyyMMDD'),
            supply: this.state.supply,
            fvat: this.state.fvat,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/kompozytyZakup', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    render() {
        this.createSelectBox()
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body" >
                    <article>
                        <h1>Zakupy kompozyty</h1>
                        <div className="Form-row"> <p className="Form-label">Element :</p>
                            <Select
                                className="select"
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                        <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Jednostka :</p>  <input className="Form-input" name = "digit" value={this.state.digit} onChange={this.handleInputChange}/></div>
                        <div className="Form-row"> <p className="Form-label">Ilość: </p> <input className="Form-input" name = "number" value={this.state.number} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Cena :</p> <input className="Form-input" name = "price" value={this.state.price} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Koszt przesyłki :</p> <input className="Form-input" name = "deliver" value={this.state.deliver} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">NIP Dostawcy :</p> <input className="Form-input" name = "supply" value={this.state.supply} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Numer faktury :</p> <input className="Form-input" name = "fvat" value={this.state.fvat} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Zakup kompozytów</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default ZakupyKompozyty
