import React, {Fragment} from 'react'
import './App.css';
import Select from "react-select";
import * as AuthService from "./Service/AuthService";
import { Redirect } from "react-router-dom";

let rowLog = [];
let dataLog = [];
let arrayLog = [];

class MainPage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            NIP: 0,
            road: '',
            number: '',
            code: '',
            town: '',
            redirectToReferrer: false,
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/klienciLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog = []
            rowLog[0] = this.state.jsoninLog[i].NIP;
            rowLog[1] = this.state.jsoninLog[i].Nazwa;
            rowLog[2] = this.state.jsoninLog[i].Ulica;
            rowLog[3] = this.state.jsoninLog[i].Number;
            rowLog[4] = this.state.jsoninLog[i].Kod;
            rowLog[6] = this.state.jsoninLog[i].Miasto;
            dataLog.push(rowLog);
            let obj = {};
            obj['value'] = this.state.jsoninLog[i].NIP;
            obj['label'] = this.state.jsoninLog[i].NIP;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>NIPy</td><td>Nazwa</td><td>Ulica</td><td>Numer</td><td>Kod</td><td>Miasto</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    onSubmit(event){

        const myObj = {
            name: this.state.name,
            NIP: this.state.NIP,
            road: this.state.road,
            number: this.state.number,
            code: this.state.code,
            town: this.state.town,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendKlient', requestOptions)
            .then(response => (
                console.log(response),
                response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/klienciLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }


    render() {

        //let { redirectToReferrer } = this.state.redirectToReferrer;
        //if (!redirectToReferrer) return <Redirect to="/" />;
    this.createTableLog();
    return(
        <Fragment>
        <div className="Main-page">
            <section className="Body">
                <article>
                    <h1>Dodaj klienta</h1>
                    <div className="Form-row"> <p className="Form-label">Nazwa klienta :</p>  <input className="Form-input" name = "name" value={this.state.name} onChange={this.handleInputChange}/></div>
                    <div className="Form-row"> <p className="Form-label">NIP/PESEL: </p> <input className="Form-input" name = "NIP" value={this.state.NIP} onChange={this.handleInputChange}/> </div>
                    <h2>Adres</h2>
                    <div className="Form-row"> <p className="Form-label">Ulica :</p> <input className="Form-input" name = "road" value={this.state.road} onChange={this.handleInputChange}/> </div>
                    <div className="Form-row"> <p className="Form-label">Numer domu/mieszkania :</p> <input className="Form-input" name = "number" value={this.state.number} onChange={this.handleInputChange}/> </div>
                    <div className="Form-row"> <p className="Form-label">Kod pocztowy :</p> <input className="Form-input" name = "code" value={this.state.code} onChange={this.handleInputChange}/> </div>
                    <div className="Form-row"> <p className="Form-label">Miasto :</p> <input className="Form-input" name = "town" value={this.state.town} onChange={this.handleInputChange}/> </div>
                    <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                    <h3>Klienci</h3>
                    <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                    </table>
                    <div className="Form-row">
                        <Select
                            className="select"
                            value={this.state.selectedLog}
                            onChange={this.handleChangeLog}
                            options={arrayLog}
                        >
                        </Select>
                        <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                    </div>
                </article>
            </section>
        </div>
        </Fragment>
    );
    }
}

export default MainPage;
