import React from 'react'
import './App.css';


class WycinainieTyl extends React.Component{
    render() {
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Pozostałe zakupy</h1>
                    </article>
                </section>
            </div>
        );
    }
}

export default WycinainieTyl
