import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';


let nity;

let optionsSzybki = [];
let optionsPaski = [];
let arrayWorkers = [];

let rowLog = [];
let dataLog = [];
let arrayLog = [];


class Nitowanie extends React.Component{constructor(props) {
    super(props);
    this.state = {
        day:'',
        month:'',
        year:'',
        OK: '',
        NOK: '',
        operator:'',
        jsonPaski: '',
        jsonSzybki: '',
        selectedPaski: '',
        selectedSzybki: '',
        jsoninLog: '',
        selectedLog: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
}


    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/oklejanie')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonPaski: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pwMinus')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonSzybki: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/nitowanieLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };


    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog[0] = this.state.jsoninLog[i].NumerSeryjny;
            rowLog[1] = this.state.jsoninLog[i].Data;
            rowLog[2] = this.state.jsoninLog[i].SeriaPaski;
            rowLog[3] = this.state.jsoninLog[i].SeriaSzybki;
            rowLog[4] = this.state.jsoninLog[i].Wykonal;
            rowLog[5] = this.state.jsoninLog[i].OK;
            rowLog[6] = this.state.jsoninLog[i].NOK;
            rowLog[7] = this.state.jsoninLog[i].Zostalo;
            dataLog.push(rowLog);
            rowLog = [];
            let obj = {};
            obj['value'] = this.state.jsoninLog[i].NumerSeryjny;
            obj['label'] = this.state.jsoninLog[i].NumerSeryjny;
            obj['OK'] = this.state.jsoninLog[i].OK;
            obj['NOK'] = this.state.jsoninLog[i].NOK;
            obj['paski'] = this.state.jsoninLog[i].SeriaPaski;
            obj['szybki'] = this.state.jsoninLog[i].SeriaSzybki;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Numer seryjny</td><td>Data</td><td>Seria pasków</td><td>Seria szybek</td><td>Wykonał</td><td>OK</td><td>NOK</td><td>Zostało</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value,
            value: parseInt(this.state.selectedLog.OK) + parseInt(this.state.selectedLog.NOK),
            szybki: this.state.selectedLog.szybki,
            paski: this.state.selectedLog.paski,
        }
        const myObjTylStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/nitowanieLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szybkiBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/nitowanieBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        nity = (parseInt(this.state.selectedLog.OK) + parseInt(this.state.selectedLog.NOK))*4;

        const magazynObj4 = {
            name: 'Nity',
            value: nity.toString(),
        };

        const magazynJson4 = JSON.stringify(magazynObj4);


        let requestMagazyn4 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynJson4,
        };


        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', requestMagazyn4)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    createSelectBox() {
        optionsSzybki = [];
        for (let i in this.state.jsonSzybki)
        {
            let obj = {};
            obj['value'] = this.state.jsonSzybki[i].NumerSerii;
            obj['label'] = this.state.jsonSzybki[i].NumerSerii;
            optionsSzybki.push(obj);
            console.log(obj);
        }

        optionsPaski = [];
        for (let i in this.state.jsonPaski)
        {
            let obj = {};
            obj['value'] = this.state.jsonPaski[i].NumerSeryjny;
            obj['label'] = this.state.jsonPaski[i].NumerSeryjny;
            optionsPaski.push(obj);
            console.log(obj);
        }
        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            let obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }
    }

    handleChangePaski = selectedPaski => {
        this.setState(
            { selectedPaski },
            () => console.log(`Option selected:`, this.state.selectedPaski)
        );
    };

    handleChangeSzybki = selectedSzybki => {
        this.setState(
            { selectedSzybki },
            () => console.log(`Option selected:`, this.state.selectedSzybki)
        );
    };

    onSubmit(event) {

        const myObj = {
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            operator: this.state.operator.value,
            OK: this.state.OK,
            NOK: this.state.NOK,
            sheet: this.state.selectedSzybki.value,
            pasek: this.state.selectedPaski.value,
            id: this.state.selectedPaski.value + '.' + this.state.selectedSzybki.value,
            value: parseInt(this.state.selectedLog.OK) + parseInt(this.state.selectedLog.NOK)
        };
        const myObjStr = JSON.stringify(myObj);

        const arkuszeObj = {
            name: this.state.selectedPaski.value,
            value: parseInt(this.state.OK) + parseInt(this.state.NOK),
        };

        const arkuszeObjStr = JSON.stringify(arkuszeObj);

        let arkuszeObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr
        };

        const arkuszeObj2 = {
            name: this.state.selectedSzybki.value,
            value: parseInt(this.state.OK) + parseInt(this.state.NOK),
        };

        const arkuszeObjStr2 = JSON.stringify(arkuszeObj2);

        let arkuszeObjOptions2 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr2
        };


        nity = (parseInt(this.state.OK) + parseInt(this.state.NOK))*4;

        const magazynObj4 = {
            name: 'Nity',
            value: nity.toString(),
        };

        const magazynJson4 = JSON.stringify(magazynObj4);


        console.log(arkuszeObjStr);

        let requestMagazyn4 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynJson4,
        };


        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', requestMagazyn4)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/nitowanie', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/paskiMinus', arkuszeObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/czyszczenieMinus', arkuszeObjOptions2)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })


    }
    render() {
        this.createSelectBox();
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Nitowanie</h1>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                                className="date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="dd-MM-yyyy" /> </div>
                        <div className="Form-row"> <p className="Form-label">Seria pasków: </p>
                        <Select
                            className="select"
                            value={this.state.selectedPaski}
                            onChange={this.handleChangePaski}
                            options={optionsPaski}
                        >
                        </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Seria szybek: </p>

                        <Select
                            className="select"
                            value={this.state.selectedSzybki}
                            onChange={this.handleChangeSzybki}
                            options={optionsSzybki}
                        >
                        </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">OK :</p> <input className="Form-input" name = "OK" value={this.state.OK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">NOK :</p> <input className="Form-input" name = "NOK" value={this.state.NOK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Znitował(a) :</p>
                            <Select
                            className="select"
                            value={this.state.operator}
                            onChange={this.handleChangeWorker}
                            options={arrayWorkers}
                        >
                        </Select></div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Nitowanie</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default Nitowanie
