import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';


let gumaMetry;
let sruby;
let podkladki;
let nakretki;
let arrayWorkers = [];
let obj = {};

let rowLogGuma = [];
let dataLogGuma = [];
let arrayLogGuma = [];

let rowLogPianki = [];
let dataLogPianki = [];
let arrayLogPianki = [];

let rowLogZestawy = [];
let dataLogZestawy = [];
let arrayLogZestawy = [];

const options = [
    { value: 'guma', label: 'Cięcie gumy' },
    { value: 'pianka', label: 'Podklejanie pianki' },
    { value: 'zestawy', label: 'Kompletowanie worków' },
]


class Inne extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
            startDate: '',
            done:0,
            operator:'',
            jsoninLogGuma: '',
            selectedLogGuma: '',
            jsoninLogPianki: '',
            selectedLogPianki: '',
            jsoninLogZestawy: '',
            selectedLogZestawy: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gumaLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogGuma: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/piankaLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogPianki: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zestawyLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogZestawy: data.data,
                })
            })
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };


    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };


    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }


    onSubmit(event) {
        const myObj = {
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            operator: this.state.operator.value,
            done: this.state.done,
        };

        const myObjStr = JSON.stringify(myObj);

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };

        console.log(myObjStr);

        if (this.state.selectedOption.value == 'guma')
        {
            console.log('guma')

            const magazynObj = {
                name: 'GumaPocieta',
                value: this.state.done,
            }

            const magazynStr = JSON.stringify(magazynObj);

            let magazynOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazyn', magazynOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            gumaMetry = parseFloat(this.state.done)/4;

            const magazynObj2 = {
                name: 'Guma',
                value: gumaMetry.toString(),
            }

            const magazynStr2 = JSON.stringify(magazynObj2);

            let magazynOptions2 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr2
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions2)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })



            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/guma', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }
        if(this.state.selectedOption.value == 'pianka')
        {
            console.log('pianki')

            const magazynObj = {
                name: 'PiankaGotowa',
                value: this.state.done,
            }

            const magazynStr = JSON.stringify(magazynObj);

            let magazynOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazyn', magazynOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            const magazynObj2 = {
                name: 'Pianka pod głowę',
                value: this.state.done,
            }

            const magazynStr2 = JSON.stringify(magazynObj2);

            let magazynOptions2 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr2
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions2)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pianka', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }
        if(this.state.selectedOption.value == 'zestawy')
        {
            console.log('zestawy')

            const magazynObj = {
                name: 'Zestawy',
                value: this.state.done,
            }

            const magazynStr = JSON.stringify(magazynObj);

            let magazynOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazyn', magazynOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            nakretki = parseInt(this.state.done) * 2;
            sruby = parseInt(this.state.done) * 2;
            podkladki = parseInt(this.state.done)* 4;

            const magazynObj2 = {
                name: 'Śruby',
                value: sruby.toString(),
            }

            const magazynStr2 = JSON.stringify(magazynObj2);

            let magazynOptions2 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr2
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions2)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            const magazynObj3 = {
                name: 'Nakrętki',
                value: nakretki.toString(),
            }

            const magazynStr3 = JSON.stringify(magazynObj3);

            let magazynOptions3 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr3
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions3)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            const magazynObj4 = {
                name: 'Podkładki',
                value: podkladki.toString(),
            }

            const magazynStr4 = JSON.stringify(magazynObj4);

            let magazynOptions4 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr4
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions4)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })



            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zestawy', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }
    }

    createSelectBox()
    {
        //<input className="Form-input" name = "element" value={this.state.element} onChange={this.handleInputChange}/>

        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }


    }

    createTableLog(){
        rowLogGuma = [];
        dataLogGuma = [];
        arrayLogGuma = [];

        for(let i in this.state.jsoninLogGuma)
        {
            rowLogGuma[0] = this.state.jsoninLogGuma[i].id;
            rowLogGuma[1] = this.state.jsoninLogGuma[i].Data;
            rowLogGuma[2] = this.state.jsoninLogGuma[i].Wykonal;
            rowLogGuma[3] = this.state.jsoninLogGuma[i].Ilosc;
            dataLogGuma.push(rowLogGuma);
            rowLogGuma = [];
            obj = {};
            obj['value'] = this.state.jsoninLogGuma[i].id;
            obj['label'] = this.state.jsoninLogGuma[i].id;
            obj['ilosc'] = this.state.jsoninLogGuma[i].Ilosc;
            arrayLogGuma.push(obj);
        }


        rowLogPianki = [];
        dataLogPianki = [];
        arrayLogPianki = [];

        for(let i in this.state.jsoninLogPianki)
        {
            rowLogPianki[0] = this.state.jsoninLogPianki[i].id;
            rowLogPianki[1] = this.state.jsoninLogPianki[i].Data;
            rowLogPianki[2] = this.state.jsoninLogPianki[i].Wykonal;
            rowLogPianki[3] = this.state.jsoninLogPianki[i].Ilosc;
            dataLogPianki.push(rowLogPianki);
            rowLogPianki = [];
            obj = {};
            obj['value'] = this.state.jsoninLogPianki[i].id;
            obj['label'] = this.state.jsoninLogPianki[i].id;
            obj['ilosc'] = this.state.jsoninLogPianki[i].Ilosc;
            arrayLogPianki.push(obj);
        }

        rowLogZestawy = [];
        dataLogZestawy = [];
        arrayLogZestawy = [];

        for(let i in this.state.jsoninLogZestawy)
        {
            rowLogZestawy[0] = this.state.jsoninLogZestawy[i].id;
            rowLogZestawy[1] = this.state.jsoninLogZestawy[i].Data;
            rowLogZestawy[2] = this.state.jsoninLogZestawy[i].Wykonal;
            rowLogZestawy[3] = this.state.jsoninLogZestawy[i].Ilosc;
            dataLogZestawy.push(rowLogZestawy);
            rowLogZestawy = [];
            obj = {};
            obj['value'] = this.state.jsoninLogZestawy[i].id;
            obj['label'] = this.state.jsoninLogZestawy[i].id;
            obj['ilosc'] = this.state.jsoninLogZestawy[i].Ilosc;
            arrayLogZestawy.push(obj);
        }
    }

    onSubmitGuma(event)
    {
        const myObjTyl = {
            id: this.state.selectedLogGuma.id
        }

        const myObjTylStr = JSON.stringify(myObjTyl);

        let requestOptionsTyl = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gumaLog', requestOptionsTyl)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        gumaMetry = (parseInt(this.state.selectedLogGuma.ilosc))/4;

        const magazynObj2 = {
            name: 'Guma',
            value: gumaMetry.toString(),
        }

        const magazynStr2 = JSON.stringify(magazynObj2);

        let magazynOptions2 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr2
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', magazynOptions2)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj3 = {
            name: 'Guma pocieta',
            value: this.state.selectedLogGuma.ilosc,
        }

        const magazynStr3 = JSON.stringify(magazynObj3);

        let magazynOptions3 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr3
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions3)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    onSubmitPianki(event)
    {
        const myObjTyl = {
            id: this.state.selectedLogPianki.value
        }

        const myObjTylStr = JSON.stringify(myObjTyl);

        let requestOptionsTyl = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/piankaLog', requestOptionsTyl)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj2 = {
            name: 'Pianka pod głowę',
            value: this.state.selectedLogPianki.ilosc,
        }

        const magazynStr2 = JSON.stringify(magazynObj2);

        let magazynOptions2 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr2
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', magazynOptions2)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj3 = {
            name: 'Pianka gotowa',
            value: this.state.selectedLogPianki.ilosc,
        }

        const magazynStr3 = JSON.stringify(magazynObj3);

        let magazynOptions3 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr3
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions3)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    onSubmitZestawy(event)
    {
        const myObjTyl = {
            id: this.state.selectedLogZestawy.value
        }

        const myObjTylStr = JSON.stringify(myObjTyl);

        let requestOptionsTyl = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zestawyLog', requestOptionsTyl)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        nakretki = this.state.selectedLogZestawy.ilosc * 2;
        sruby = this.state.selectedLogZestawy.ilosc * 2;
        podkladki = this.state.selectedLogZestawy.ilosc * 4;

        const magazynObj2 = {
            name: 'Śruby',
            value: sruby.toString(),
        }

        const magazynStr2 = JSON.stringify(magazynObj2);

        let magazynOptions2 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr2
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', magazynOptions2)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj3 = {
            name: 'Nakrętki',
            value: nakretki.toString(),
        }

        const magazynStr3 = JSON.stringify(magazynObj3);

        let magazynOptions3 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr3
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', magazynOptions3)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj4 = {
            name: 'Podkładki',
            value: podkladki.toString(),
        }

        const magazynStr4 = JSON.stringify(magazynObj4);

        let magazynOptions4 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr4
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', magazynOptions4)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj5 = {
            name: 'Zestawy',
            value: this.state.selectedLogZestawy.ilosc,
        }

        const magazynStr5 = JSON.stringify(magazynObj5);

        let magazynOptions5 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr5
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions5)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }


    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Id</td><td>Data</td><td>Operator</td><td>Ilosc</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeGuma = selectedLogGuma => {
        this.setState(
            { selectedLogGuma },
            () => console.log(`Option selected:`, this.state.selectedLogGuma)
        );
    };

    handleChangePianki = selectedLogPianki => {
        this.setState(
            { selectedLogPianki },
            () => console.log(`Option selected:`, this.state.selectedLogPianki)
        );
    };

    handleChangeZestawy = selectedLogZestawy => {
        this.setState(
            { selectedLogZestawy },
            () => console.log(`Option selected:`, this.state.selectedLogZestawy)
        );
    };

    render() {
        this.createSelectBox()
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Wykonał(a) :</p>
                            <Select
                            className="select"
                            value={this.state.operator}
                            onChange={this.handleChangeWorker}
                            options={arrayWorkers}
                        >
                        </Select> </div>
                        <div className="Form-row"> <p className="Form-label">Zrobione :</p> <input className="Form-input" name = "done" value={this.state.done} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij"  className="btn"/>
                        <h3>Cięcie gumy</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLogGuma)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogGuma}
                                onChange={this.handleChangeGuma}
                                options={arrayLogGuma}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitGuma.bind(this)} value="Usuń" className="btn"/>
                        </div>
                        <h3>Podklejanie pianek</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLogPianki)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogPianki}
                                onChange={this.handleChangePianki}
                                options={arrayLogPianki}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitPianki.bind(this)} value="Usuń" className="btn"/>
                        </div>
                        <h3>Kompletowanie zestawy</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLogZestawy)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogZestawy}
                                onChange={this.handleChangeZestawy}
                                options={arrayLogZestawy}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitZestawy.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }

}

export default Inne
