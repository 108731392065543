import React from 'react'
import './App.css';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import Select from "react-select";


let rowLog = [];
let dataLog = [];
let arrayLog = [];

class ZakupSzybki extends React.Component{

constructor(props) {
    super(props);
    this.state = {
        startDate: '',
        day: '',
        month: '',
        year: '',
        material: '',
        area: 0,
        thick: 0,
        number: 0,
        price: 0,
        deliver: 0,
        supply: '',
        jsoninLog: '',
        selectedLog: '',

    };
    this.handleInputChange = this.handleInputChange.bind(this);
}

componentDidMount() {
    fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zakupSzybkiLog')
        .then(response => (
            console.log(response),
                response.json()
        ))
        .then(data => {
            console.log(data);
            this.setState({
                jsoninLog: data.data,
            })
        })
}

    handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    console.log('test');
    this.setState({
        [name]: value });
}

handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog = []
            rowLog[0] = this.state.jsoninLog[i].id;
            rowLog[1] = this.state.jsoninLog[i].Surowiec;
            rowLog[2] = this.state.jsoninLog[i].Powierzchnia;
            rowLog[3] = this.state.jsoninLog[i].Grubosc;
            rowLog[4] = this.state.jsoninLog[i].Ilosc;
            rowLog[6] = this.state.jsoninLog[i].Cena;
            rowLog[7] = this.state.jsoninLog[i].Przesylka;
            rowLog[8] = this.state.jsoninLog[i].Wartosc;
            rowLog[9] = this.state.jsoninLog[i].Dostawca;
            rowLog[10] = this.state.jsoninLog[i].Pozostalo;
            dataLog.push(rowLog);
            let obj = {};
            obj['value'] = this.state.jsoninLog[i].id;
            obj['label'] = this.state.jsoninLog[i].id;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>id</td><td>Surowiec</td><td>Powierzchnia</td><td>Grubość</td><td>Ilość</td><td>Cena</td><td>Przesyłka</td><td>Wartość</td><td>Dostawca</td><td>Zostało</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zakupSzybkiLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

onSubmit(event){

    const myObj = {
        day: Moment(this.state.startDate).format('yyyyMMDD'),
        material: this.state.material,
        area: this.state.area,
        thick: this.state.thick,
        number: this.state.number,
        price: this.state.price,
        deliver: this.state.deliver,
        supply: this.state.supply,
    };
    const myObjStr = JSON.stringify(myObj);

    console.log(myObjStr);

    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: myObjStr
    };
    fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szybkiZakup', requestOptions)
        .then(response => (
            console.log(response),
                response.json()
        ))
        .then(data => {
            console.log(data);
        })
}

    render() {
        this.createTableLog();
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Zakup Szybki</h1>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                                className="date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="dd-MM-yyyy" />
                        </div>
                        <div className="Form-row"> <p className="Form-label">Materiał: </p> <input className="Form-input" name = "material" value={this.state.material} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Powierzchnia: </p> <input className="Form-input" name = "area" value={this.state.area} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Grubość: </p> <input className="Form-input" name = "thick" value={this.state.thick} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Ilość: </p> <input className="Form-input" name = "number" value={this.state.number} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Cena: </p> <input className="Form-input" name = "price" value={this.state.price} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Przesyłka: </p> <input className="Form-input" name = "deliver" value={this.state.deliver} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Dostawca: </p> <input className="Form-input" name = "supply" value={this.state.supply} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Zakupy szybki</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }

}


export default ZakupSzybki
