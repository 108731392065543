import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';

let options = [];
let obj = {};
let arrayWorkers = [];

let rowLog = [];
let dataLog = [];
let arrayLog = [];



class Czyszczenie extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            sheets:'',
            OK: '',
            NOK: '',
            operator:'',
            selectedOption: '',
            jsonin: '',
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieSzybki')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonin: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/czyszczenieLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value });
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };

    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog =[]
        for (let i in this.state.jsoninLog) {
            rowLog[0] = this.state.jsoninLog[i].id;
            rowLog[1] = this.state.jsoninLog[i].Data;
            rowLog[2] = this.state.jsoninLog[i].Wykonal;
            rowLog[3] = this.state.jsoninLog[i].OK;
            rowLog[4] = this.state.jsoninLog[i].NOK;
            rowLog[5] = this.state.jsoninLog[i].NumerSerii;
            dataLog.push(rowLog);
            rowLog = [];
            obj = {};
            obj['value'] = this.state.jsoninLog[i].id;
            obj['label'] = this.state.jsoninLog[i].id;
            obj['seria'] = this.state.jsoninLog[i].NumerSerii;
            obj['OK'] = this.state.jsoninLog[i].OK;
            obj['NOK'] = this.state.jsoninLog[i].NOK;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Id</td><td>Data</td><td>Wykonał(a)</td><td>OK</td><td>NOK</td><td>Numer serii</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value,
            value: parseInt(this.state.selectedLog.OK) + parseInt(this.state.selectedLog.NOK),
            name: this.state.selectedLog.seria,
        }
        const myObjTylStr = JSON.stringify(myObjLog);
        console.log(myObjTylStr);
        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/czyszczenieLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/czyszczenieBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }


    createSelectBox()
    {
        //<input className="Form-input" name = "element" value={this.state.element} onChange={this.handleInputChange}/>
        options = [];
        for (let i in this.state.jsonin)
        {
            let obj = {};
            obj['value'] = this.state.jsonin[i].NumerSerii;
            obj['label'] = this.state.jsonin[i].NumerSerii;
            options.push(obj);
            console.log(obj);
        }

        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }

    }

    onSubmit(event) {

        const myObj = {
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            seria: this.state.selectedOption.value,
            OK: this.state.OK,
            NOK: this.state.NOK,
            operator: this.state.operator.value,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/czyszczenie', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const magazynObj = {
            name: this.state.selectedOption.value,
            value: parseInt(this.state.OK) + parseInt(this.state.NOK),
            valueOK: this.state.OK,
        };

        const magazynObjStr = JSON.stringify(magazynObj);

        console.log(myObjStr);

        let magazynObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynObjStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pwMinus', magazynObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    render() {
        this.createSelectBox();
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Czyszczenie szybek</h1>
                        <div className="Form-row"> <p className="Form-label">Numer partii</p>
                            <Select
                                className="select"
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Wykonał(a) :</p> <Select
                            className="select"
                            value={this.state.operator}
                            onChange={this.handleChangeWorker}
                            options={arrayWorkers}
                        >
                        </Select> </div>
                        <div className="Form-row"> <p className="Form-label">OK :</p> <input className="Form-input" name = "OK" value={this.state.OK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">NOK :</p> <input className="Form-input" name = "NOK" value={this.state.NOK} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Przygotowane</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }

}

export default Czyszczenie
