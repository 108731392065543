import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Link, Route, BrowserRouter} from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import MainPage from './MainPage';
import SupplyPage from './SupplyPage';
import WorkerPage from "./WorkerPage";
import WycinainieTyl from "./WycinainieTyl";
import WycinaniePrzod from "./WycinaniePrzod";
import PozostaleZakupy from "./PozostaleZakupy";
import WycinanieSzybki from "./WycinanieSzybki";
import Nitowanie from "./Nitowanie";
import Gotowe from "./Gotowe";
import ZakupyKompozyty from "./ZakupyKompozyty";
import Zamowienia from "./Zamowienia";
import Wydajnosc from "./Wydajnosc";
import ZakupSzybki from "./ZakupSzybki";
import Magazyn from "./Magazyn"
import Laminowanie from "./Laminowanie";
import Inne from "./Inne";
import StanZamowien from "./StanZamowien";

import './App.css';
import LaminacjaOtwieranie from "./LaminacjaOtwieranie";
import Czyszczenie from "./Czyszczenie";
import Szlifowanie from "./Szlifowanie";
import Oklejanie from "./Oklejanie";
import login from "./login";
import ProtectedRoute from "./Components/ProtectedRoute";
import * as AuthService from "./Service/AuthService";
import Wiercenie from "./Wiercenie";


const routs = (


    < BrowserRouter>
        <div>
            <header className="Main-header">Baza danych EnforceMed

                <button className="logout" onClick={AuthService.logout}>
                    Wyloguj
                </button>
            </header>
            <nav className="Menu-list">
                <ul className="Menu-list">
                    <row className="Menu-row">
                        <li>
                            <Link to="/magazyn"><p className="Menu-item">Stan magazynowy</p></Link>
                        </li>
                        <li>
                            <Link to="/laminowanie"><p className="Menu-item">Laminowanie</p></Link>
                        </li>
                        <li>
                            <Link to="/otwieranie"><p className="Menu-item">Otwieranie laminatu</p></Link>
                        </li>
                        <li>
                            <Link to="/wycinanie"><p className="Menu-item">StepCraft</p></Link>
                        </li>
                        <li>
                            <Link to="/wiercenie"><p className="Menu-item">Wiercenie</p></Link>
                        </li>
                        <li>
                            <Link to="/szlifowanie"><p className="Menu-item">Szlifowanie</p></Link>
                        </li>
                        <li>
                            <Link to="/oklejanie"><p className="Menu-item">Oklejanie</p></Link>
                        </li>
                        <li>
                            <Link to="/czyszczenie"><p className="Menu-item">Przygotowanie szybek</p></Link>
                        </li>
                        <li>
                            <Link to="/nitowanie"><p className="Menu-item">Nitowanie</p></Link>
                        </li>
                        <li>
                            <Link to="/gotowe"><p className="Menu-item">Montaż</p></Link>
                        </li>
                        <li>
                            <Link to="/inne"><p className="Menu-item">Inne</p></Link>
                        </li>
                    </row>
                    <row className="Menu-row">
                        <li>
                            <Link to="/zamowienia"><p className="Menu-itemblue">Zamówienia</p></Link>
                        </li>
                        <li>
                            <Link to="/stan"><p className="Menu-itemblue">Stan zamówień</p></Link>
                        </li>
                        <li>
                            <Link to="/klient"><p className="Menu-itemblue">Dodaj klienta</p></Link>
                        </li>
                        <li>
                            <Link to="/supply"><p className="Menu-itemblue">Dodaj dostawcę</p></Link>
                        </li>
                        <li>
                            <Link to="/workers"><p className="Menu-itemblue">Dodaj pracownika</p></Link>
                        </li>
                        <li>
                            <Link to="/zakupSzybki"><p className="Menu-itemblue">Zakup szybki</p></Link>
                        </li>
                        <li>
                            <Link to="/kompozytyZakup"><p className="Menu-itemblue">Zakup kompozyty</p></Link>
                        </li>
                        <li>
                            <Link to="/pozostaleZakupy"><p className="Menu-itemblue">Pozostałe zakupy</p></Link>
                        </li>
                        <li>
                            <Link to="/wycinanieSzybki"><p className="Menu-itemblue">Wycinanie szybki</p></Link>
                        </li>
                    </row>
                </ul>
            </nav>
            <Route exact path="/" component={login}/>
            <Route path="/login" exact component={login} />
            <ProtectedRoute path="/klient" excat component={MainPage}/>
            <ProtectedRoute path="/supply" component={SupplyPage}/>
            <ProtectedRoute path="/workers" component={WorkerPage}/>
            <ProtectedRoute path="/kompozytyZakup" component={ZakupyKompozyty}/>
            <ProtectedRoute path="/pozostaleZakupy" component={PozostaleZakupy}/>
            <ProtectedRoute path="/wycinanie" component={WycinaniePrzod}/>
            <ProtectedRoute path="/wycinanieTyl" component={WycinainieTyl}/>
            <ProtectedRoute path="/nitowanie" component={Nitowanie}/>
            <ProtectedRoute path="/gotowe" component={Gotowe}/>
            <ProtectedRoute path="/wycinanieSzybki" component={WycinanieSzybki}/>
            <ProtectedRoute path="/zamowienia" component={Zamowienia}/>
            <ProtectedRoute path="/wydajnosc" component={Wydajnosc}/>
            <ProtectedRoute path="/zakupSzybki" component={ZakupSzybki}/>
            <ProtectedRoute path="/laminowanie" component={Laminowanie}/>
            <ProtectedRoute path="/magazyn" component={Magazyn}/>
            <ProtectedRoute path="/inne" component={Inne}/>
            <ProtectedRoute path="/stan" component={StanZamowien} />
            <ProtectedRoute path="/otwieranie" component={LaminacjaOtwieranie} />
            <ProtectedRoute path="/czyszczenie" component={Czyszczenie} />
            <ProtectedRoute path="/szlifowanie" component={Szlifowanie} />
            <ProtectedRoute path="/oklejanie" component={Oklejanie} />
            <ProtectedRoute path="/wiercenie" component={Wiercenie} />
        </div>
    </ BrowserRouter>
)




ReactDOM.render(routs, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
