import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';


let options = [];
let obj = {};
let optionsPaski = [
    { value: 'backk', label: 'Paski tylne otwory' },
    { value: 'slot', label: 'Paski tylne szczeliny'},
    { value: 'front', label: 'Paski przednie' },
];
let arrayWorkers = [];

let rowLog = [];
let dataLogPrzod = [];
let arrayLogPrzod = [];
let dataLogTyl = [];
let arrayLogTyl = [];
let dataLogSzczelina = [];
let arrayLogSzczelina = [];



class Szlifowanie extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            sheets:'',
            OK: '',
            NOK: '',
            operator:'',
            selectedOption: '',
            selectedPaski: '',
            jsonin: '',
            jsoninPrzod: '',
            jsoninSzlif: '',
            jsoninLogPrzod: '',
            selectedLogPrzod: '',
            jsoninLogTyl: '',
            selectedLogTyl: '',
            jsoninLogSzczelina: '',
            selectedLogSzczelina: '',

        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wiercenieTyl')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonin: data.data,
                })
            })
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wierceniePrzod')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninPrzod: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanie')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninSzlif: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieTylLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogTyl: data.data,
                })
            })
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowaniePrzodLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogPrzod: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieSzczelinaLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogSzczelina: data.data,
                })
            })


    }

    createSelectBox()
    {
        //<input className="Form-input" name = "element" value={this.state.element} onChange={this.handleInputChange}/>
        options = [];
        for (let i in this.state.jsonin)
        {
            let obj = {};
            obj['value'] = this.state.jsonin[i].NumerSeryjny;
            obj['label'] = this.state.jsonin[i].NumerSeryjny;
            options.push(obj);
            console.log(obj);
        }

        for (let i in this.state.jsoninPrzod)
        {
            let obj = {};
            obj['value'] = this.state.jsoninPrzod[i].NumerSeryjny;
            obj['label'] = this.state.jsoninPrzod[i].NumerSeryjny;
            options.push(obj);
            console.log(obj);
        }

        for (let i in this.state.jsoninSzlif)
        {
            let obj = {};
            obj['value'] = this.state.jsoninSzlif[i].NumerSeryjny;
            obj['label'] = this.state.jsoninSzlif[i].NumerSeryjny;
            options.push(obj);
            console.log(obj);
        }

        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }


    }

    createTableLog() {
        rowLog = []
        dataLogPrzod = []
        arrayLogPrzod = []
        for (let i in this.state.jsoninLogPrzod) {
            rowLog = []
            rowLog[0] = this.state.jsoninLogPrzod[i].NumerSeryjny;
            rowLog[1] = this.state.jsoninLogPrzod[i].Data;
            rowLog[2] = this.state.jsoninLogPrzod[i].Wykonal;
            rowLog[3] = this.state.jsoninLogPrzod[i].OK;
            rowLog[4] = this.state.jsoninLogPrzod[i].NOK;
            rowLog[6] = this.state.jsoninLogPrzod[i].Zostalo;
            dataLogPrzod.push(rowLog);
            obj = {};
            obj['value'] = this.state.jsoninLogPrzod[i].NumerSeryjny;
            obj['label'] = this.state.jsoninLogPrzod[i].NumerSeryjny;
            obj['OK'] = this.state.jsoninLogPrzod[i].OK;
            obj['NOK'] = this.state.jsoninLogPrzod[i].NOK;
            arrayLogPrzod.push(obj);
        }

        rowLog = []
        dataLogTyl = []
        arrayLogTyl = []
        for (let i in this.state.jsoninLogTyl) {
            rowLog = []
            rowLog[0] = this.state.jsoninLogTyl[i].NumerSeryjny;
            rowLog[1] = this.state.jsoninLogTyl[i].Data;
            rowLog[2] = this.state.jsoninLogTyl[i].Wykonal;
            rowLog[3] = this.state.jsoninLogTyl[i].OK;
            rowLog[4] = this.state.jsoninLogTyl[i].NOK;
            rowLog[6] = this.state.jsoninLogTyl[i].Zostalo;
            dataLogTyl.push(rowLog);
            obj = {};
            obj['value'] = this.state.jsoninLogTyl[i].NumerSeryjny;
            obj['label'] = this.state.jsoninLogTyl[i].NumerSeryjny;
            obj['OK'] = this.state.jsoninLogTyl[i].OK;
            obj['NOK'] = this.state.jsoninLogTyl[i].NOK;
            arrayLogTyl.push(obj);
        }

        rowLog = []
        dataLogSzczelina = []
        arrayLogSzczelina = []
        for (let i in this.state.jsoninLogSzczelina) {
            rowLog = []
            rowLog[0] = this.state.jsoninLogSzczelina[i].NumerSeryjny;
            rowLog[1] = this.state.jsoninLogSzczelina[i].Data;
            rowLog[2] = this.state.jsoninLogSzczelina[i].Wykonal;
            rowLog[3] = this.state.jsoninLogSzczelina[i].OK;
            rowLog[4] = this.state.jsoninLogSzczelina[i].NOK;
            rowLog[6] = this.state.jsoninLogSzczelina[i].Zostalo;
            dataLogSzczelina.push(rowLog);
            obj = {};
            obj['value'] = this.state.jsoninLogSzczelina[i].NumerSeryjny;
            obj['label'] = this.state.jsoninLogSzczelina[i].NumerSeryjny;
            obj['OK'] = this.state.jsoninLogSzczelina[i].OK;
            obj['NOK'] = this.state.jsoninLogSzczelina[i].NOK;
            arrayLogSzczelina.push(obj);
        }
    }

    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };


    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Numer seryjny</td><td>Data</td><td>Wykonał</td><td>OK</td><td>NOK</td><td>Zostało</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value });
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    handleChangePaski = selectedPaski => {
        this.setState(
            { selectedPaski },
            () => console.log(`Option selected:`, this.state.selectedPaski)
        );
    };


    handleChangeLogPrzod = selectedLogPrzod => {
        this.setState(
            { selectedLogPrzod },
            () => console.log(`Option selected:`, this.state.selectedLogPrzod)
        );
    };

    handleChangeLogTyl = selectedLogTyl => {
        this.setState(
            { selectedLogTyl },
            () => console.log(`Option selected:`, this.state.selectedLogTyl)
        );
    };

    handleChangeLogSzczelina = selectedLogSzczelina => {
        this.setState(
            { selectedLogSzczelina },
            () => console.log(`Option selected:`, this.state.selectedLogSzczelina)
        );
    };

    onSubmit(event) {
        const myObj = {
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            id: this.state.selectedOption.value,
            OK: this.state.OK,
            NOK: this.state.NOK,
            operator: this.state.operator.value,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };

        const arkuszeObj = {
            name: this.state.selectedOption.value,
            value: parseInt(this.state.OK) + parseInt(this.state.NOK),
        };

        const arkuszeObjStr = JSON.stringify(arkuszeObj);
        console.log(arkuszeObj);

        let arkuszeObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr
        };

        if(this.state.selectedPaski.value == 'front') {
            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowaniePrzod', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowaniePrzodMinus', arkuszeObjOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }

        else if(this.state.selectedPaski.value == 'backk') {
            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanie', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieMinus', arkuszeObjOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }

        else if(this.state.selectedPaski.value == 'slot') {
            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieSzczelina', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieSzczelinaMinus', arkuszeObjOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }

    }

    onSubmitLogPrzod(event) {
        const myObjLog = {
            id: this.state.selectedLogPrzod.value,
            value: parseInt(this.state.selectedLogPrzod.OK) + parseInt(this.state.selectedLogPrzod.NOK)
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowaniePrzodLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowaniePrzodBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    onSubmitLogTyl(event) {
        const myObjLog = {
            id: this.state.selectedLogTyl.value,
            value: parseInt(this.state.selectedLogTyl.OK) + parseInt(this.state.selectedLogTyl.NOK)
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieTylLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieTylBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    onSubmitLogSzczelina(event) {
        const myObjLog = {
            id: this.state.selectedLogSzczelina.value,
            value: parseInt(this.state.selectedLogSzczelina.OK) + parseInt(this.state.selectedLogSzczelina.NOK)
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieSzczelinaLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })


        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieSzczelinaBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    render() {
        this.createSelectBox()
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Szlifowanie</h1>
                        <Select
                            className="select"
                            value={this.state.selectedPaski}
                            onChange={this.handleChangePaski}
                            options={optionsPaski}
                        >
                        </Select>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Numer serii :</p>
                            <Select
                                className="select"
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Wykonał(a) :</p> <Select
                            className="select"
                            value={this.state.operator}
                            onChange={this.handleChangeWorker}
                            options={arrayWorkers}
                        >
                        </Select> </div>
                        <div className="Form-row"> <p className="Form-label">OK :</p> <input className="Form-input" name = "OK" value={this.state.OK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">NOK :</p> <input className="Form-input" name = "NOK" value={this.state.NOK} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Szlifowanie przod</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLogPrzod)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogPrzod}
                                onChange={this.handleChangeLogPrzod}
                                options={arrayLogPrzod}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLogPrzod.bind(this)} value="Usuń" className="btn"/>
                        </div>
                        <h3>Szlifowanie tyl</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLogTyl)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogTyl}
                                onChange={this.handleChangeLogTyl}
                                options={arrayLogTyl}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLogTyl.bind(this)} value="Usuń" className="btn"/>
                        </div>
                        <h3>Szlifowanie szczeliny</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLogSzczelina)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogSzczelina}
                                onChange={this.handleChangeLogSzczelina}
                                options={arrayLogSzczelina}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLogSzczelina.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default Szlifowanie
