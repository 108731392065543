import React, { Fragment, Component } from "react";
import * as AuthService from "./Service/AuthService";
import { Redirect } from "react-router-dom";

class login extends Component {
    state = { redirectToReferrer: false };

    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value });
    }


    loginHandler = () => {
        if (this.state.login == 'EnforceMed' && this.state.password == 'En2020force') {
            console.log("Came to login handler");
            AuthService.login();
            this.setState({redirectToReferrer: true});
        }
    };
    render() {
        let { from } = this.props.location.state || { from: { pathname: "/" } };
        let { redirectToReferrer } = this.state;
        if (redirectToReferrer) return <Redirect to={from} />;

        return (
            <Fragment>
                <div className="Main-page">
                <h1 className="content-header">
                    Zaloguj się:
                </h1>
                <div className="Form-row"> <p className="Form-label">Login :</p> <input className="Form-input" name = "login" value={this.state.login} onChange={this.handleInputChange}/> </div>
                <div className="Form-row"> <p className="Form-label">Hasło :</p> <input className="Form-input" name = "password" value={this.state.password} onChange={this.handleInputChange} type="password"/> </div>
                <div className="justify-center">
                    <button className="btn btn-danger" onClick={this.loginHandler}>
                        Zaloguj
                    </button>
                </div>
                </div>
            </Fragment>
        );
    }
}
export default login;
