import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

const options = [
    { value: 'backk', label: 'Paski tylne' },
    { value: 'front', label: 'Paski przednie' },
];

let optionsPlyta = [];
let obj = {};
let arrayNOK = [];
let arraySend = [];
let arrayWorkers = [];
let rowTyl = [];
let tableDataTyl = [];
let rowPrzod = [];
let tableDataPrzod = [];
let logPrzod = [];
let logTyl = [];


class WycinaniePrzod extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            year:'',
            sheets:'',
            OK: '',
            NOK: '',
            operator:'',
            tools:0,
            selectedOption: '',
            jsonin: '',
            inputNOK: '',
            startDate: '',
            jasoninWorker: '',
            jsoninLogTyl: '',
            jsoninLogPrzod: '',
            selectedLogTyl: '',
            selectedLogPrzod: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/plytyKompozyt')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonin: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieTylLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogTyl: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinaniePrzodLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLogPrzod: data.data,
                })
            })
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.value)
        );
    };

    handleChangePlyta = sheets => {
        this.setState(
            { sheets },
            () => console.log(`Option selected:`, this.state.sheets)
        );
    };

    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };

    handleChangePrzod = selectedLogPrzod => {
        this.setState(
            { selectedLogPrzod },
            () => console.log(`Option selected:`, this.state.selectedLogPrzod)
        );
    };

    handleChangeTyl = selectedLogTyl => {
        this.setState(
            { selectedLogTyl },
            () => console.log(`Option selected:`, this.state.selectedLogTyl)
        );
    };

    createSelectBoxPlya()
    {
        optionsPlyta = [];
        for (let i in this.state.jsonin)
        {
            obj = {};
            obj['value'] = this.state.jsonin[i].NumerSeryjny;
            obj['label'] = this.state.jsonin[i].NumerSeryjny;
            optionsPlyta.push(obj);
        }

        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }

    }

    createTableTyl() {
        rowTyl = []
        tableDataTyl = []
        logTyl = []
        for(let i in this.state.jsoninLogTyl)
        {
                rowTyl[0] = this.state.jsoninLogTyl[i].NumerSeryjny;
                rowTyl[1] = this.state.jsoninLogTyl[i].Data;
                rowTyl[2] = this.state.jsoninLogTyl[i].Operator;
                rowTyl[3] = this.state.jsoninLogTyl[i].OK;
                rowTyl[4] = this.state.jsoninLogTyl[i].NOK;
                rowTyl[5] = this.state.jsoninLogTyl[i].WymienioneFrezy;
                rowTyl[6] = this.state.jsoninLogTyl[i].Wyciente
                tableDataTyl.push(rowTyl);
                rowTyl = [];
                obj = {};
                obj['value'] = this.state.jsoninLogTyl[i].NumerSeryjny;
                obj['label'] = this.state.jsoninLogTyl[i].NumerSeryjny;
                logTyl.push(obj);
        }

        rowPrzod = []
        tableDataPrzod = []
        logPrzod = []
        for(let i in this.state.jsoninLogPrzod) {
            rowPrzod[0] = this.state.jsoninLogPrzod[i].NumerSeryjny;
            rowPrzod[1] = this.state.jsoninLogPrzod[i].Data;
            rowPrzod[2] = this.state.jsoninLogPrzod[i].Operator;
            rowPrzod[3] = this.state.jsoninLogPrzod[i].OK;
            rowPrzod[4] = this.state.jsoninLogPrzod[i].NOK;
            rowPrzod[5] = this.state.jsoninLogPrzod[i].WymienioneFrezy;
            rowPrzod[6] = this.state.jsoninLogPrzod[i].Zostalo
            tableDataPrzod.push(rowPrzod);
            rowPrzod = [];
            obj = {};
            obj['value'] = this.state.jsoninLogPrzod[i].NumerSeryjny;
            obj['label'] = this.state.jsoninLogPrzod[i].NumerSeryjny;
            logPrzod.push(obj);
        }

    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Numer seryjny</td><td>Data</td><td>Operator</td><td>OK</td><td>NOK</td><td>Frezy</td><td>Zostało</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }


    noSubmitDel(event) {

        const myObjTyl = {
            id: this.state.selectedLogTyl.value
        }

        const myObjTylStr = JSON.stringify(myObjTyl);

        let requestOptionsTyl = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieTylLog', requestOptionsTyl)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/plytaBack', requestOptionsTyl)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    noSubmitDelPrzod(event) {
        const myObjPrzod = {
            id: this.state.selectedLogPrzod.value
        }

        const myObjPrzodStr = JSON.stringify(myObjPrzod);

        let requestOptionsPrzod = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjPrzodStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinaniePrzodLog', requestOptionsPrzod)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/plytaBack', requestOptionsPrzod)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    onSubmit(event) {

        console.log(this.state.value)

        const myObj = {
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            sheets: this.state.sheets.value,
            OK: this.state.OK,
            NOK: this.state.NOK,
            operator: this.state.operator.value,
            tools: this.state.tools
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        const sheetObj = {
            name: 'Plyta',
            value: this.state.sheets
        }

        const sheetStr = JSON.stringify(sheetObj);

        let requestSheet = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: sheetStr
        };


        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', requestSheet)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const frezObj = {
            name: 'Frezy',
            value: this.state.sheets
        }

        const frezStr = JSON.stringify(frezObj);

        let requestFrez = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: frezStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', requestFrez)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })


        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', requestSheet)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };
        if(this.state.selectedOption.value == 'front') {

            arraySend = [];
            arrayNOK = [];
            let number = parseInt(this.state.OK) + parseInt(this.state.NOK);
            let j = 0;
            if(this.state.inputNOK != '') {
                arrayNOK = this.state.inputNOK.split(',');
                console.log('test');
            }
            console.log(number);
            for (var i=0; i < number; i++)
            {
                if(parseInt(arrayNOK[j])-1==i)
                {
                    arraySend.push('NOK')
                    j=j+1;
                    console.log('chuj')
                }
                else {
                    arraySend.push('OK');
                }

            }

            console.log(arraySend);

            const plytyObj = {
                id: this.state.sheets.value,
                ok: arraySend,
            }

            const plytyObjStr = JSON.stringify(plytyObj);
            console.log(plytyObjStr);


            let requestOptionsplyty = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: plytyObjStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pasekPrzod', requestOptionsplyty)
                .then(response => (
                    console.log(response)
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pytaMinus', requestOptionsplyty)
                .then(response => (
                    console.log(response)
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinaniePrzod', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
            const magazynObj = {
                name: 'PasekPrzodWyciety',
                value: this.state.done,
            }

            const magazynStr = JSON.stringify(magazynObj);

            let requestMagazyn = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazyn', requestMagazyn)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

        }
        else if(this.state.selectedOption.value == 'backk') {

            arraySend = [];
            arrayNOK = [];
            let number = parseInt(this.state.OK) + parseInt(this.state.NOK);
            let j = 0;
            if(this.state.inputNOK != '') {
                arrayNOK = this.state.inputNOK.split(',');
                console.log('test');
            }
            console.log(number);
            for (var i=0; i < number; i++)
            {
                if(parseInt(arrayNOK[j])-1==i)
                {
                    arraySend.push('NOK')
                    j=j+1;
                    console.log('chuj')
                }
                else {
                    arraySend.push('OK');
                }

            }

            console.log(arraySend);

            const plytyObj = {
                id: this.state.sheets.value,
                ok: arraySend,
            }

            const plytyObjStr = JSON.stringify(plytyObj);
            console.log(plytyObjStr);


            let requestOptionsplyty = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: plytyObjStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pasekTyl', requestOptionsplyty)
                .then(response => (
                    console.log(response)
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pytaMinus', requestOptionsplyty)
                .then(response => (
                    console.log(response)
                ))
                .then(data => {
                    console.log(data);
                })

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieTyl', requestOptions)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
            const magazynObj = {
                name: 'PasekTylWycieny',
                value: this.state.OK,
            }

            const magazynStr = JSON.stringify(magazynObj)

            let requestMagazyn = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazyn', requestMagazyn)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })

        }

    }
    render() {
        this.createSelectBoxPlya()
        this.createTableTyl()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Wycinanie</h1>
                        <Select
                            className="select"
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={options}
                        >
                        </Select>
                        <div></div>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" />
                        </div>
                        <div className="Form-row"> <p className="Form-label">Wycięta płyta :</p>

                            <Select
                                className="select"
                                value={this.state.sheets}
                                onChange={this.handleChangePlyta}
                                options={optionsPlyta}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">OK :</p> <input className="Form-input" name = "OK" value={this.state.OK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">NOK :</p> <input className="Form-input" name = "NOK" value={this.state.NOK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Numery NOK:</p> <input className="Form-input" name = "inputNOK" value={this.state.inputNOK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Wykonał(a) :</p>
                            <Select
                                className="select"
                                value={this.state.operator}
                                onChange={this.handleChangeWorker}
                                options={arrayWorkers}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Zużyte frezy :</p> <input className="Form-input" name = "tools" value={this.state.tools} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Wycinanie tyl</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(tableDataTyl)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                            className="select"
                            value={this.state.selectedLogTyl}
                            onChange={this.handleChangeTyl}
                            options={logTyl}
                        >
                        </Select>
                            <input type="button" onClick={this.noSubmitDel.bind(this)} value="Usuń" className="btn"/>
                        </div>
                        <h3>Wycinanie przod</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(tableDataPrzod)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLogPrzod}
                                onChange={this.handleChangePrzod}
                                options={logPrzod}
                            >
                            </Select>
                            <input type="button" onClick={this.noSubmitDelPrzod.bind(this)} value="Usuń" className="btn"/>
                        </div>

                    </article>
                </section>
            </div>
        );
    }
}

export default WycinaniePrzod
