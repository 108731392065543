import React from 'react'
import './App.css';

let names = [];
let values = [];

class Magazyn extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            jsonin: '',
            plyty: '',
            szybki: '',
            czyste: '',
            tylstep: '',
            tylgotowo: '',
            przodStep: '',
            przodSzlif: '',
            oklejone: '',
            znitowane: '',
            wierconePrzod: '',
            wierconeTyl: '',
            gotowe: '',
            zlorzone: '',
            jsoninPlyty: '',
            jsoninSzybki: '',
            jsoninZnitowane: '',
            jsoninWyczyszczone: '',
            jsoninTylStep:'',
            jsoninTylGotowe: '',
            jsoninPrzodStep: '',
            jsoninPrzodSzlif: '',
            jsoninPrzodNakl: '',
            jsoninGotowe: '',
            jsoninWiercenieTyl: '',
            jsoninWierceniePrzod: '',
        };
    }


   componentDidMount() {
       console.log('flaga2');
       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazyn')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsonin: data.data,
               })
           })
       console.log('flaga2');

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/plytyKompozyt')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninPlyty: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieSzybki')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninSzybki: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pwMinus')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninWyczyszczone: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/nitowanie')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninZnitowane: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pasekTyl')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninTylStep: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanie')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninTylGotowe: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/pasekPrzod')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninPrzodStep: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotowe')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninGotowe: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowaniePrzod')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninPrzodSzlif: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/oklejanie')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninPrzodNakl: data.data,
               })
           })

       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wiercenieTyl')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninWiercenieTyl: data.data,
               })
           })
       fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wierceniePrzod')
           .then(response => (
               console.log(response),
                   response.json()
           ))
           .then(data => {
               console.log(data);
               this.setState({
                   jsoninWierceniePrzod: data.data,
               })
           })

   }

    tableData()
    {
        //console.log(this.state.jsonin[3].Nazwa)
        for (let i in this.state.jsonin)
        {
            console.log(this.state.jsonin[i])
            names[i] = this.state.jsonin[i].Nazwa
            values[i] = this.state.jsonin[i].Ilosc
        }

        this.state.plyty = 0;
        for (let i in this.state.jsoninPlyty)
        {
            this.state.plyty = this.state.plyty+1 ;
        }
        this.state.szybki = 0;
        for (let i in this.state.jsoninSzybki)
        {
            this.state.szybki = this.state.szybki + parseInt(this.state.jsoninSzybki[i].Zostalo);
        }

        this.state.czyste = 0;
        for (let i in this.state.jsoninWyczyszczone)
        {
            this.state.czyste = this.state.czyste + parseInt(this.state.jsoninWyczyszczone[i].DostepneOK);
        }

        this.state.znitowane = 0;
        for (let i in this.state.jsoninZnitowane)
        {
            this.state.znitowane = this.state.znitowane + parseInt(this.state.jsoninZnitowane[i].Zostalo);
        }

        this.state.tylstep = 0;
        for (let i in this.state.jsoninTylStep)
        {
            this.state.tylstep = this.state.tylstep + parseInt(this.state.jsoninTylStep[i].Wyciente);
        }

        this.state.tylgotowo = 0;
        for (let i in this.state.jsoninTylGotowe)
        {
            this.state.tylgotowo = this.state.tylgotowo + parseInt(this.state.jsoninTylGotowe[i].Zostalo);
        }

        this.state.przodStep = 0
        for (let i in this.state.jsoninPrzodStep)
        {
            this.state.przodStep = this.state.przodStep + parseInt(this.state.jsoninPrzodStep[i].Zostalo);
        }

        this.state.gotowe = 0;
        this.state.zlorzone = 0;

        for (let i in this.state.jsoninGotowe)
        {
            if(this.state.jsoninGotowe[i].Zlorzone == '1')
            {
                this.state.zlorzone = this.state.zlorzone + parseInt(this.state.jsoninGotowe[i].Zostalo);
            }
            if(this.state.jsoninGotowe[i].Zlorzone == '0')
            {
                this.state.gotowe = this.state.gotowe + parseInt(this.state.jsoninGotowe[i].Zostalo);
            }

        }

        this.state.przodSzlif = 0;

        for (let i in this.state.jsoninPrzodSzlif)
        {
            this.state.przodSzlif = this.state.przodSzlif + parseInt(this.state.jsoninPrzodSzlif[i].Zostalo);
        }

        this.state.wierconePrzod = 0;
        for (let i in this.state.jsoninWierceniePrzod)
        {
            this.state.wierconePrzod = this.state.wierconePrzod + parseInt(this.state.jsoninWierceniePrzod[i].Zostalo);
        }

        this.state.wierconeTyl = 0;
        for (let i in this.state.jsoninWiercenieTyl)
        {
            this.state.wierconeTyl = this.state.wierconeTyl + parseInt(this.state.jsoninWiercenieTyl[i].Zostalo);
        }

        this.state.oklejone = 0;

        for (let i in this.state.jsoninPrzodNakl)
        {
            this.state.oklejone = this.state.oklejone + parseInt(this.state.jsoninPrzodNakl[i].Zostalo);
        }

    }



    render() {
        this.tableData()
        return (
            <div className="Main-page">
                <section className="Body" >
                    <article>
                        <h1>Magazyn</h1>
                        <table className="table">
                            <tr>
                                <td className="td">Płyty</td>
                                <td className="td">{this.state.plyty.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Wycięte szybki</td>
                                <td className="td">{this.state.szybki.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Czyste szybki</td>
                                <td className="td">{this.state.czyste.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Paski tył wycięte</td>
                                <td className="td">{this.state.tylstep.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Paski tył rozwiercone</td>
                                <td className="td">{this.state.wierconeTyl.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Paski tył gotowe</td>
                                <td className="td">{this.state.tylgotowo.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Pasek przód wycięte</td>
                                <td className="td">{this.state.przodStep.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Pasek przód rozwiercone</td>
                                <td className="td">{this.state.wierconePrzod.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Pasek przód oszlifowane</td>
                                <td className="td">{this.state.przodSzlif.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Pasek przód oklejone</td>
                                <td className="td">{this.state.oklejone.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Znitowane</td>
                                <td className="td">{this.state.znitowane.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Przyłbice karton</td>
                                <td className="td">{this.state.gotowe.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">Przyłbice złożone</td>
                                <td className="td">{this.state.zlorzone.toString()}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[0]}</td>
                                <td className="td">{values[0]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[1]}</td>
                                <td className="td">{values[1]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[2]}</td>
                                <td className="td">{values[2]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[3]}</td>
                                <td className="td">{values[3]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[4]}</td>
                                <td className="td">{values[4]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[5]}</td>
                                <td className="td">{values[5]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[6]}</td>
                                <td className="td">{values[6]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[7]}</td>
                                <td className="td">{values[7]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[8]}</td>
                                <td className="td">{values[8]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[9]}</td>
                                <td className="td">{values[9]}</td>
                            </tr>
                            <tr>
                                <td className="td">{names[10]}</td>
                                <td className="td">{values[10]}</td>
                            </tr>
                        </table>
                    </article>
                </section>
            </div>
        );
    }

}

export default Magazyn
