import React from 'react'
import './App.css';
import Select from "react-select";


let row = [];
let tableData = [];
let value = '';
let options = [];
let optionsGotowe = [];

class StanZamowien extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            jsonin: '',
            gotowe: [],
            jsoninGotowe: '',
            selectedOption: '',
            OK: '',
            selectedGotowe: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }





    componentDidMount() {

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zamowienia')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonin: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotowe')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninGotowe: data.data,
                })
            })
    }

    createTable() {
        row = []
        tableData = []
        for(let i in this.state.jsonin)
        {
            if(this.state.jsonin[i].Zostalo>0) {
                row[0] = this.state.jsonin[i].id;
                row[1] = this.state.jsonin[i].Termin;
                row[2] = this.state.jsonin[i].Ilosc;
                row[3] = this.state.jsonin[i].Wartosc;
                row[4] = this.state.jsonin[i].Zostalo;
                tableData.push(row);
                console.log(row);
                row = [];
            }
        }
        console.log(tableData);

    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<th><td>Termin</td><td>Ilość</td><td>Wartość</td></th>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                this.state.gotowe[data] = false;
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    onSubmit(event){
        const arkuszeObj = {
            name: this.state.selectedOption.value,
            seria: this.state.selectedGotowe.value,
            value: parseInt(this.state.OK),
        };

        const arkuszeObjStr = JSON.stringify(arkuszeObj);

        let arkuszeObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/stanMinus', arkuszeObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zamowieniaMinus', arkuszeObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    onSubmitDel(event) {
        const delObj = {
            id: this.state.selectedOption.value,
        };

        const delObjStr = JSON.stringify(delObj);

        let delObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: delObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/zamowieniaDel', delObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    createSelectBox()
    {
        //<input className="Form-input" name = "element" value={this.state.element} onChange={this.handleInputChange}/>
        options = []

        for (let i in this.state.jsonin)
        {
            if(this.state.jsonin[i].Zostalo>0) {
                let obj = {};
                obj['value'] = this.state.jsonin[i].id;
                obj['label'] = 'Zamówienie nr: ' + this.state.jsonin[i].id;
                options.push(obj);
                console.log(obj);
            }
        }

        optionsGotowe = [];
        for (let i in this.state.jsoninGotowe)
        {
            let obj = {};
            obj['value'] = this.state.jsoninGotowe[i].NumerSeryjny;
            obj['label'] = this.state.jsoninGotowe[i].NumerSeryjny;
            optionsGotowe.push(obj);
            console.log(obj);
        }

    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value });
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    handleChangeGotowe = selectedGotowe => {
        this.setState(
            { selectedGotowe },
            () => console.log(`Option selected:`, this.state.selectedGotowe)
        );
    };

    render() {
        this.createTable();
        this.createSelectBox()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h3>Zamówienia oczkujące</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(tableData)}}>
                        </table>
                        <div className="Form-row"> <p className="Form-label">Numer zamówienia :</p>
                        <Select
                            className="select"
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={options}
                        >
                        </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Numer serii :</p>
                            <Select
                                className="select"
                                value={this.state.selectedGotowe}
                                onChange={this.handleChangeGotowe}
                                options={optionsGotowe}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Ilość :</p> <input className="Form-input" name = "OK" value={this.state.OK} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)}  value="Gotowe" className="btn"/>
                        <input type="button" onClick={this.onSubmitDel.bind(this)}  value="Usuń" className="btn"/>
                    </article>
                </section>
            </div>
        );
    }

}
export  default StanZamowien
