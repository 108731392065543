import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';

let options = [];
let obj = {};
let dataLog = [];
let arrayLog = [];
let rowLog = [];

class WycinanieSzybki extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            sheets: '',
            number: '',
            money: '',
            jsonin: '',
            seria: '',
            selectedOptions:'',
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szybkiZakup')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonin: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieSzybkiLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }


    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }


    createSelectBox()
    {
        options = [];
        for (let i in this.state.jsonin)
        {
            obj = {};
            obj['value'] = this.state.jsonin[i].id;
            obj['label'] = this.state.jsonin[i].id;
            options.push(obj);
            console.log(obj);
        }
    }


    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog = []
            rowLog[0] = this.state.jsoninLog[i].NumerSerii;
            rowLog[1] = this.state.jsoninLog[i].Data;
            rowLog[2] = this.state.jsoninLog[i].LiczbaArkuszy;
            rowLog[3] = this.state.jsoninLog[i].UzyskaneSzybki;
            rowLog[4] = this.state.jsoninLog[i].KosztNetto;
            rowLog[5] = this.state.jsoninLog[i].Zostalo;
            rowLog[8] = this.state.jsoninLog[i].DostepneOK;
            dataLog.push(rowLog);
            obj = {};
            obj['value'] = this.state.jsoninLog[i].NumerSerii;
            obj['label'] = this.state.jsoninLog[i].NumerSerii;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Numer seryjny</td><td>Data</td><td>Liczna arkuszy</td><td>Uzyskane szybki</td><td>Liczba szybek</td><td>Zostało</td><td>Dostępne</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value
        }
        const myObjLogStr = JSON.stringify(myObjLog);

        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieSzybkiLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }



    onSubmit(event) {

        const myObj = {
            id: this.state.selectedOption.value + '.' + this.state.seria,
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            sheets: this.state.sheets,
            number: this.state.number,
            money: this.state.money,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/wycinanieSzybki', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        const arkuszeObj = {
            name: this.state.selectedOption.value,
            value: this.state.sheets,
        };

        const arkuszeObjStr = JSON.stringify(arkuszeObj);

        let arkuszeObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/arkuszeMinus', arkuszeObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })
    }

    render() {
        this.createSelectBox()
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Wycinanie szybki</h1>
                        <div className="Form-row"> <p className="Form-label">Numer partii</p>
                        <Select
                            className="select"
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={options}
                        >
                        </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Numer wycinania :</p>  <input className="Form-input" name = "seria" value={this.state.seria} onChange={this.handleInputChange}/></div>
                        <div className="Form-row"> <p className="Form-label">Data :</p>
                            <DatePicker
                                className="date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Wycięte arkusze: </p> <input className="Form-input" name = "sheets" value={this.state.sheets} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Uzyskane szybki: </p> <input className="Form-input" name = "number" value={this.state.number} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Koszt: </p> <input className="Form-input" name = "money" value={this.state.money} onChange={this.handleInputChange}/> </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Wycinanie szybki</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                            </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default WycinanieSzybki
