import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';


const options = [
    {value: '8', label: '8'},
    {value: '10', label: '10'},
    {value: '12', label: '12'}
];

let arrayWorkers = [];
let obj = {};

let rowLog = [];
let dataLog = [];
let arrayLog = [];

class Laminowanie extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            operator: '',
            operator2: '',
            sheets: '',
            window: '',
            selectedOption: '',
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/laminowanieLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value });
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };

    handleChangeWorker2 = operator2 => {
        this.setState(
            { operator2 },
            () => console.log(`Option selected:`, this.state.operator2)
        );
    };



    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog=[]
            rowLog[0] = this.state.jsoninLog[i].id;
            rowLog[1] = this.state.jsoninLog[i].DataLaminacji;
            rowLog[2] = this.state.jsoninLog[i].Wykonawca1;
            rowLog[3] = this.state.jsoninLog[i].Wykonawca2;
            rowLog[4] = this.state.jsoninLog[i].NrSzyby;
            rowLog[6] = this.state.jsoninLog[i].LiczbaPlyt;
            dataLog.push(rowLog);
            obj = {};
            obj['value'] = this.state.jsoninLog[i].id;
            obj['label'] = this.state.jsoninLog[i].id;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Numer laminacji</td><td>Data Laminacji</td><td>Wykonał</td><td>Wykonał</td><td>Numer szyby</td><td>Liczba płyt</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    createSelectBox()
    {
        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }

    }


    onSubmit(event) {
        const myObj = {
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            operator: this.state.operator.value,
            operator2: this.state.operator2.value,
            sheets: this.state.selectedOption.value,
            window: this.state.window,
        };

        const myObjStr = JSON.stringify(myObj);

        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: myObjStr
        };
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/laminowanie', requestOptions)
            .then(response => (
                console.log(response)
            ))
            .then(data => {
                console.log(data);
            })

    }

    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.label
        }
        const myObjLogStr = JSON.stringify(myObjLog);
        console.log(myObjLogStr);
        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjLogStr
        };

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/laminowanieLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

    }

    render() {
        this.createSelectBox()
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Laminowanie</h1>
                        <div className="Form-row"> <p className="Form-label">Data laminacji :</p>
                            <DatePicker
                                className="date"
                                selected={this.state.startDate}
                                onChange={this.handleChangeDate}
                                dateFormat="dd-MM-yyyy" />
                        </div>
                        <div className="Form-row"> <p className="Form-label">Laminował(a) :</p>
                            <Select
                                className="select"
                                value={this.state.operator}
                                onChange={this.handleChangeWorker}
                                options={arrayWorkers}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Laminował(a) :</p>
                            <Select
                                className="select"
                                value={this.state.operator2}
                                onChange={this.handleChangeWorker2}
                                options={arrayWorkers}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Numer szyby :</p> <input className="Form-input" name = "window" value={this.state.window} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Uzyskane płyty :</p>
                            <Select
                                className="select"
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            >
                            </Select>
                        </div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Laminacje</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default Laminowanie
