import React from 'react'
import './App.css';
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from 'moment';


let optionsTyl = [];
let optionsPrzod = [];
let arrayWorkers = [];
const options = [
    {value: '0', label:'Karton'},
    {value: '1', label:'Złożone'}
]

let rowLog = [];
let dataLog = [];
let arrayLog = [];


class Gotowe extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            OK:0,
            NOK:0,
            operator:'',
            selectedTyl: '',
            selectedPrzod: '',
            selectedOption: '',
            jsonTyl: '',
            jsonPrzod: '',
            jsoninLog: '',
            selectedLog: '',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        console.log('test');
        this.setState({
            [name]: value });
    }

    componentDidMount() {
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/szlifowanieSzczelina')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonTyl: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/nitowanie')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsonPrzod: data.data,
                })
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/sendWorker')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jasoninWorker: data.data,
                })
            })
        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotoweLog')
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
                this.setState({
                    jsoninLog: data.data,
                })
            })
    }

    createTableLog() {
        rowLog = []
        dataLog = []
        arrayLog = []
        for (let i in this.state.jsoninLog) {
            rowLog[0] = this.state.jsoninLog[i].NumerSeryjny;
            rowLog[1] = this.state.jsoninLog[i].Data;
            rowLog[2] = this.state.jsoninLog[i].NumerTyl;
            rowLog[3] = this.state.jsoninLog[i].NumerPrzod;
            rowLog[2] = this.state.jsoninLog[i].Wykonal;
            rowLog[3] = this.state.jsoninLog[i].OK;
            rowLog[4] = this.state.jsoninLog[i].NOK;
            rowLog[6] = this.state.jsoninLog[i].Zostalo;
            dataLog.push(rowLog);
            rowLog = [];
            let obj = {};
            obj['value'] = this.state.jsoninLog[i].NumerSeryjny;
            obj['label'] = this.state.jsoninLog[i].NumerSeryjny;
            obj['OK'] = this.state.jsoninLog[i].OK;
            obj['NOK'] = this.state.jsoninLog[i].NOK;
            obj['pasek'] = this.state.jsoninLog[i].NumerTyl;
            obj['szybki'] = this.state.jsoninLog[i].NumerPrzod;
            obj['zlorzone'] = this.state.jsoninLog[i].Zlorzone;
            arrayLog.push(obj);
        }
    }

    generateTable(data) {
        let html = '';

        if (typeof (data[0]) === 'undefined') {
            return null;
        }

        html += '<tr><td>Numer seryjny</td><td>Data</td><td>Oklejał</td><td>OK</td><td>NOK</td><td>Zostało</td></tr>'

        if (data[0].constructor === Array) {
            for (var row in data) {
                html += '<tr>\r\n';
                for (var item in data[row]) {
                    html += '<td>' + data[row][item] + '</td>\r\n';
                }
                html += '</tr>\r\n';
            }
        }
        return html;
    }

    handleChangeLog = selectedLog => {
        this.setState(
            { selectedLog },
            () => console.log(`Option selected:`, this.state.selectedLog)
        );
    };

    onSubmitLog(event) {
        const myObjLog = {
            id: this.state.selectedLog.value,
            value: parseInt(this.state.selectedLog.OK) + parseInt(this.state.selectedLog.NOK),
            tyl: this.state.selectedLog.pasek,
            przod:this.state.selectedLog.szybki
        }
        const myObjTylStr = JSON.stringify(myObjLog);
        console.log(myObjTylStr);
        let requestOptionsLog = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjTylStr
        };


        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotoweLog', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotoweBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/montazBack', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotowePlus', requestOptionsLog)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        if(this.state.selectedLog.zlorzone == '1')
        {
            const magazynObj2 = {
                name: 'Karton jednostkowy',
                value: parseInt(this.state.selectedLog.OK),
            }

            const magazynStr2 = JSON.stringify(magazynObj2);

            let magazynOptions2 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr2
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynMinus', magazynOptions2)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }

    }

    handleChangeWorker = operator => {
        this.setState(
            { operator },
            () => console.log(`Option selected:`, this.state.operator)
        );
    };


    handleChangeDate = startDate => {
        this.setState({
            startDate
        });
    }

    createSelectBox() {
        optionsTyl = [];
        for (let i in this.state.jsonTyl)
        {
            let obj = {};
            obj['value'] = this.state.jsonTyl[i].NumerSeryjny;
            obj['label'] = this.state.jsonTyl[i].NumerSeryjny;
            optionsTyl.push(obj);
            console.log(obj);
        }

        optionsPrzod = [];
        for (let i in this.state.jsonPrzod)
        {
            let obj = {};
            obj['value'] = this.state.jsonPrzod[i].NumerSeryjny;
            obj['label'] = this.state.jsonPrzod[i].NumerSeryjny;
            optionsPrzod.push(obj);
            console.log(obj);
        }

        arrayWorkers = [];
        for (let i in this.state.jasoninWorker)
        {
            let obj = {};
            obj['value'] = this.state.jasoninWorker[i].id;
            obj['label'] = this.state.jasoninWorker[i].id;
            arrayWorkers.push(obj);
        }
    }

    handleChangePaski = selectedTyl => {
        this.setState(
            { selectedTyl },
            () => console.log(`Option selected:`, this.state.selectedPaski)
        );
    };

    handleChangeSzybki = selectedPrzod => {
        this.setState(
            { selectedPrzod },
            () => console.log(`Option selected:`, this.state.selectedSzybki)
        );
    };

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };

    onSubmit(event) {

        const myObj = {
            id: this.state.selectedTyl.value + '.' + this.state.selectedPrzod.value,
            day:  Moment(this.state.startDate).format('yyyyMMDD'),
            OK: this.state.OK,
            NOK: this.state.NOK,
            operator: this.state.operator.value,
            back: this.state.selectedTyl.value,
            front: this.state.selectedPrzod.value,
            status: this.state.selectedOption.value,
        };
        const myObjStr = JSON.stringify(myObj);

        console.log(myObjStr);

        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: myObjStr
        };

        const magazynObj = {
            name: 'Gotowe',
            value: this.state.done,
        }

        const magazynStr = JSON.stringify(magazynObj);

        let requestMagazyn = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: magazynStr
        };

        const arkuszeObj = {
            name: this.state.selectedTyl.value,
            value: parseInt(this.state.OK) + parseInt(this.state.NOK),
        };

        const arkuszeObjStr = JSON.stringify(arkuszeObj);

        let arkuszeObjOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr
        };

        const arkuszeObj2 = {
            name: this.state.selectedPrzod.value,
            value: parseInt(this.state.OK) + parseInt(this.state.NOK),
        };

        const arkuszeObjStr2 = JSON.stringify(arkuszeObj2);

        let arkuszeObjOptions2 = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: arkuszeObjStr2
        };

        const gotoweObj = {
            value: this.state.OK
        }

        const gotoweStr = JSON.stringify(gotoweObj);

        let requestGotowe = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
            body: gotoweStr
        }

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotowe', requestOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/gotoweMinus', requestGotowe)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/glowaMinus', arkuszeObjOptions)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        fetch('https://test.enforcemed.pl/pl/api/apiVerbum/znitowaneMinus', arkuszeObjOptions2)
            .then(response => (
                console.log(response),
                    response.json()
            ))
            .then(data => {
                console.log(data);
            })

        if (this.state.selectedOption.value == '1')
        {
            const magazynObj2 = {
                name: 'Karton jednostkowy',
                value: this.state.OK,
            }

            const magazynStr2 = JSON.stringify(magazynObj2);

            let magazynOptions2 = {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
                body: magazynStr2
            };

            fetch('https://test.enforcemed.pl/pl/api/apiVerbum/magazynPlus', magazynOptions2)
                .then(response => (
                    console.log(response),
                        response.json()
                ))
                .then(data => {
                    console.log(data);
                })
        }
    }

    render() {
        this.createSelectBox()
        this.createTableLog()
        return (
            <div className="Main-page">
                <section className="Body">
                    <article>
                        <h1>Gotowe</h1>
                        <div className="Form-row"> <p className="Form-label">Dzień :</p>
                            <DatePicker
                            className="date"
                            selected={this.state.startDate}
                            onChange={this.handleChangeDate}
                            dateFormat="dd-MM-yyyy" /></div>
                        <div className="Form-row"> <p className="Form-label">Seria pasków: </p>
                            <Select
                                className="select"
                                value={this.state.selectedTyl}
                                onChange={this.handleChangePaski}
                                options={optionsTyl}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Seria szybek: </p>

                            <Select
                                className="select"
                                value={this.state.selectedPrzod}
                                onChange={this.handleChangeSzybki}
                                options={optionsPrzod}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">OK :</p> <input className="Form-input" name = "OK" value={this.state.OK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">NOK :</p> <input className="Form-input" name = "NOK" value={this.state.NOK} onChange={this.handleInputChange}/> </div>
                        <div className="Form-row"> <p className="Form-label">Status: </p>
                            <Select
                                className="select"
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            >
                            </Select>
                        </div>
                        <div className="Form-row"> <p className="Form-label">Wykonał(a) :</p>
                            <Select
                            className="select"
                            value={this.state.operator}
                            onChange={this.handleChangeWorker}
                            options={arrayWorkers}
                        >
                        </Select></div>
                        <input type="button" onClick={this.onSubmit.bind(this)} value="Wyślij" className="btn"/>
                        <h3>Montaż</h3>
                        <table border="1" dangerouslySetInnerHTML={{__html: this.generateTable(dataLog)}}>
                        </table>
                        <div className="Form-row">
                            <Select
                                className="select"
                                value={this.state.selectedLog}
                                onChange={this.handleChangeLog}
                                options={arrayLog}
                            >
                            </Select>
                            <input type="button" onClick={this.onSubmitLog.bind(this)} value="Usuń" className="btn"/>
                        </div>
                    </article>
                </section>
            </div>
        );
    }
}

export default Gotowe
